import React, { useEffect, useState } from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ConfigurationManager from './ConfigurationManager';


export default function IndexFileUpload({ agentId, tokenManager, disabled, selectedSearchAgent }) {
        const [uploadSearchDataProps, setUploadSearchDataProps] = useState(null);

    useEffect(() => {

        async function doGetApiToken()
        {
            var apiToken = await tokenManager.getAADToken();            
            //const formData = new FormData();            
            //formData.append('persona', persona);
            //formData.append('searchAgentName', selectedSearchAgent);


            setUploadSearchDataProps( {
                action: `${ConfigurationManager.getApiUrl()}SearchIndex/AddFileToIndex`,
                data: { agentId: agentId, searchAgentName: selectedSearchAgent },
                headers: {
                    /*"Content-Type": "multipart/form-data",*/
                    'Authorization': `Bearer ${apiToken}`
                },
                onChange({ file, fileList }) {
                    if (file.status !== 'uploading') {
                        console.log(file, fileList);
                    }
                },
                defaultFileList: [
                ],
            });
        }

        doGetApiToken();
    }, [agentId, selectedSearchAgent, tokenManager]);



    return (
        <div className="file-upload">
            <div>
                <h3>Upload data to search</h3>
                <p>This uploads the data into the index pointed to by the current agent. Eventually we will support mutliple indexes per agent, but for now just one.</p>
            </div>

            <Upload {...uploadSearchDataProps} >
                <Button icon={<UploadOutlined />} disabled={disabled}>Upload</Button>
            </Upload>
        </div>        
    );
};

/*data={{ persona: persona }} headers = {{ 'Authorization': 'Bearer ' + apiToken }}*/