import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Avatar, Button, Image, List, Spin } from 'antd';
import { getInferenceChat } from '../messaging';
import ConfigurationManager from '../components/ConfigurationManager';

function MessageItem({ message, index, tokenManager, commandsOn, playbackManager, imagePath }) {
    const [imgSrc, setImgSrc] = useState({});
    const [debugData, setDebugData] = useState(null);
    const [isInferenceDataVisible, setInferenceDataVisible] = useState(false);
    const isLoadingDebugData = useRef(false);

    const role = String(message.roleName).toLowerCase();

    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => (binary += String.fromCharCode(b)));
        return window.btoa(binary);
    }

    function removeTags(htmlString, tag) {
        var container = document.createElement('div');
        container.innerHTML = htmlString;
        var tags = container.querySelectorAll(tag);
        for (var i = 0; i < tags.length; i++) {
            tags[i].parentNode.removeChild(tags[i]);
        }
        return container.innerHTML;
    }

    function extractAllImageSrcs(htmlString) {
        var container = document.createElement('div');
        container.innerHTML = htmlString;
        var imgs = container.querySelectorAll('img');
        var srcs = [];
        for (var i = 0; i < imgs.length; i++) {
            srcs.push(imgs[i].getAttribute('src'));
        }
        return srcs;
    }

    function htmlEnc(s) {
        return s.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/'/g, '&#39;')
            .replace(/"/g, '&quot;');
    }

    const showInferenceData = useCallback(async () => {
        setInferenceDataVisible(!isInferenceDataVisible);
        if (!debugData && message.debugLogId && !isLoadingDebugData.current) {
            isLoadingDebugData.current = true;

            try {
                const token = await tokenManager.getAADToken();
                const fetchedDebugData = await getInferenceChat(token, message.debugLogId);
                setDebugData(fetchedDebugData);
            } catch (error) {
                console.error('Error fetching debugData:', error);
            } finally {
                isLoadingDebugData.current = false;
            }
        }
    }, [debugData, isInferenceDataVisible, isLoadingDebugData, message.debugLogId, tokenManager]);

    const getToken = useCallback(async () => {
        return await tokenManager.getAADToken();
    }, [tokenManager]);

    const renderExtra = useCallback((listHtml, imageUrls) => {
        getToken().then((aadToken) => {
            imageUrls.map((url) => {
                let fetchUrl = "";
                let corsMode = 'cors';
                if (url.startsWith('https') || url.startsWith('http')) {
                    if (url.startsWith('http')) {
                        fetchUrl = 'https' + url.substring(4);
                    }
                    else {
                        fetchUrl = url;
                    }
                    corsMode = 'no-cors';
                }
                else {
                    fetchUrl = `${ConfigurationManager.getApiUrl()}Image/${imagePath}/${url.replace(/^\/+/, '')}`;
                }
                if (!imgSrc[url]) {
                    fetch(fetchUrl, {
                        method: "GET",
                        headers: { Authorization: 'Bearer ' + aadToken },
                        mode: corsMode,
                    }).then((response) => {
                        if (response.ok) {
                            response.arrayBuffer().then((buffer) => {
                                const base64Flag = `data:${response.headers.get('content-type')};base64,`;
                                const imageStr = arrayBufferToBase64(buffer);
                                setImgSrc((prevState) => ({ ...prevState, [url]: base64Flag + imageStr }));
                            });
                        }
                        else {
                            console.warn("Image failed to load: " + fetchUrl);
                        }
                    }).catch(error => console.warn("Image failed to load: " + fetchUrl));
                }
                return "";
            });
        });
        return (<div className="item-content">

            {commandsOn && message.agentTypeString !== undefined && message.agentTypeString !== null && (
                <div><b>  {message.agentTypeString} </b></div>
            )}

            {listHtml && (
                <>
                    <div className="lists-container" dangerouslySetInnerHTML={{ __html: listHtml }}></div>
                    {message.debugLogId !== null && message.debugLogId !== undefined && message.debugLogId !== "" && commandsOn ?
                        <div>
                            {message.debugLogId && (
                                <Button onClick={showInferenceData}>
                                    {isInferenceDataVisible ? 'Hide Inference Data' : 'Show Inference Data'}
                                </Button>
                            )}
                            <Button type="primary" onClick={async () => {
                                var data = await getInferenceChat(await tokenManager.getAADToken(), message.debugLogId);
                                var element = document.createElement('a');
                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.ChatData));
                                element.setAttribute('download', "inference.json");
                                element.style.display = 'none';
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);
                            }}>Download Chat</Button>
                            {
                                debugData !== null && debugData !== undefined && commandsOn &&
                                isInferenceDataVisible && (
                                    <div className="inference-data">
                                        {isLoadingDebugData.current ? (
                                            <Spin tip="Loading inference data..." ><div /></Spin>
                                        ) : debugData ? (
                                            <div className="inference-data">
                                                <h3>Inference Data</h3>
                                                <div className="inference-data-item">
                                                    <table>
                                                        <thead>
                                                            <tr></tr>
                                                            <td ></td>
                                                            <td></td>
                                                            <tr></tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Object.keys(debugData).map((key, index) => {
                                                                    if (key !== "ChatData")
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className="inference-data-item-row-item">{key}</td>
                                                                                <td className="inference-data-item-row-item">{JSON.stringify(debugData[key], null, 2)}</td>
                                                                            </tr>
                                                                        );
                                                                    else return "";
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        ) : (
                                            <div>No inference data available.</div>
                                        )}
                                    </div>
                                )}                                                                                            
                        </div>
                        : <></>
                    }
                </>
            )}
            {imageUrls && imageUrls.length > 0 && (
                <div className="imgs-container">
                    <Image.PreviewGroup>
                        <div className="space">
                            {imageUrls.map((url, index) => {
                                return (
                                    <div className="image-backdrop" key={index}>
                                        <Image width={200} src={imgSrc[url]} alt="" className="chat-image" />
                                    </div>
                                );
                            })}
                        </div>
                    </Image.PreviewGroup>
                </div>
            )}
        </div>);
    }, [getToken, commandsOn, message.agentTypeString, message.debugLogId, showInferenceData, isInferenceDataVisible, debugData, imgSrc, imagePath, tokenManager]);



    useEffect(() => {
        // Additional setup if needed  
    }, []);

    // Prepare message content  
    var messageText = message.content;
    let listHtml = "";
    var mainmessage = "";
    mainmessage = messageText;

    
    var srcTags = extractAllImageSrcs(messageText);
    mainmessage = removeTags(mainmessage, 'img');
    mainmessage = removeTags(mainmessage, 'search');



    switch (String(message.roleName).toLowerCase()) {
        case "error":
        case "Error":
            mainmessage = htmlEnc(mainmessage);
            mainmessage = mainmessage.replaceAll(/\r\n|\r|\n/g, "<br/>");
            break;
        case "intro":
            mainmessage = htmlEnc(mainmessage);
            mainmessage = mainmessage.replaceAll(/\r\n|\r|\n/g, "<br/>");
            break;
        case "assistant":
            var codeRegex = /<code>(.*?)<\/code>/gs;
            var codeMatches = messageText.match(codeRegex);
            if (codeMatches !== null && codeMatches !== undefined) {
                codeMatches.forEach((match) => {
                    var code = match.replace(/<\/?code>/g, '');
                    mainmessage = mainmessage.replace(match, "<code>" + htmlEnc(code) + "</code>");
                });
            }
            mainmessage = mainmessage.replaceAll(/\r\n|\r|\n/g, "<br/>");
            break;
        case "user":
            mainmessage = htmlEnc(mainmessage);
            mainmessage = mainmessage.replaceAll(/\r\n|\r|\n/g, "<br/>");
            break;
        default:
            break;
    }

    //if (commandsOn) {
    //    mainmessage += `<div>id:${message.id}</div>`;
    //}
    if (!commandsOn) {
        mainmessage = removeTags(mainmessage, 'searchids');
        mainmessage = removeTags(mainmessage, 'potentialsolution');
    }

    const renderContent = () => {
        if (mainmessage !== "" || listHtml !== "" || srcTags.length !== 0 || role === "waiting" || role === "error" || (message.images && message.images.length > 0)) {
            switch (role) {
                case "intro":
                    return (
                        <List.Item key={index} className="intro-li">
                            {renderExtra(mainmessage, srcTags)}
                        </List.Item>
                    );
                case "command":
                    if (messageText.toLowerCase() === htmlEnc("<performsearch>")) {
                        return null; // Adjust as needed  
                    }
                    if (commandsOn && mainmessage.replaceAll(/\s/g, '').length) {
                        mainmessage = mainmessage.replaceAll(htmlEnc("}\r\n"), "}<br/>\r\n");
                        return (
                            <List.Item key={index} className="command-li">
                                <Avatar size={64} src={'CommandRun.png'} className="command-avatar" />
                                {renderExtra(mainmessage, srcTags)}
                            </List.Item>
                        );
                    }
                    break;
                case "user":
                    return (
                        <List.Item key={index} className="user-li">
                            <Avatar size={80} src={'img/UserAvatar.png'} className="user-avatar" />
                            {renderExtra(mainmessage, srcTags)}
                            <div className="imgs-container">
                                <Image.PreviewGroup>
                                    <div className="space">
                                        {message.images && message.images.map((img, idx) => (
                                            <Image key={idx} src={img.data} width={200} alt="" />
                                        ))}
                                    </div>
                                </Image.PreviewGroup>
                            </div>
                        </List.Item>
                    );
                case "assistant":
                    return (
                        <List.Item key={index} className="assistant-li">
                            <Avatar size={80} src={'img/AshleyAvatar.png'} className="sydney-avatar" />
                            {renderExtra(mainmessage, srcTags)}
                        </List.Item>
                    );
                case "info":
                    if (commandsOn && mainmessage.replaceAll(/\s/g, '').length) {
                        mainmessage = mainmessage.replaceAll(htmlEnc("}\r\n"), "}<br/>\r\n");
                        return (
                            <List.Item key={index} className="info-li">
                                <Avatar size={64} src={'info.png'} className="command-avatar" />                  
                                {renderExtra(mainmessage ? message.friendlySummary + "</br>" + String(mainmessage) : message.friendlySummary, srcTags)}
                            </List.Item>
                        );
                    }
                    break;
                case "Error":
                case "error":
                    return (
                        <List.Item key={index} className="error-li" title="error">
                            <Avatar size={80} src={'img/error.png'} className="sydney-avatar" />
                            {renderExtra(commandsOn ? String(mainmessage) : String(message.friendlySummary), srcTags)}
                        </List.Item>
                    );
                case "waiting":
                    return (
                        <List.Item key={index} className="assistant-li">
                            <Spin className="waiting-spinner" />
                        </List.Item>
                    );
                default:
                    break;
            }
        }
        return null;
    };

    return renderContent();
}

export default MessageItem;  